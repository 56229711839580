import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MaterialToolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MailIcon from "@material-ui/icons/Mail";
import PhoneIcon from "@material-ui/icons/Phone";
import AppBar from "@material-ui/core/AppBar";

const useStyles = makeStyles(theme => ({
  appBar: {
    top: "auto",
    background: `${process.env.REACT_APP_FOOTER_COLOR}`,
    bottom: 0
  },
  grow: {
    flexGrow: 0.5
  },
  mailLink: {
    textDecoration: "none",
    color: "inherit"
  }
}));

const supportEmail = process.env.REACT_APP_FOOTER_EMAIL_ADDR;
const mailTo = `mailto:${supportEmail}`;
const phoneNumber = process.env.REACT_APP_FOOTER_PHONE;

const Footer = props => {
  const classes = useStyles();
  return (
    <AppBar position="fixed" className={classes.appBar}>
      <MaterialToolbar>
        <div className={classes.grow} />
        <strong>
          Need Help?{" "}
          <a href={mailTo} className={classes.mailLink}>
            <IconButton color="inherit">
              <MailIcon />
            </IconButton>{" "}
            <span>{supportEmail}</span>{" "}
          </a>
          <a className={classes.mailLink} href={`tel:${phoneNumber}`}>
            <IconButton color="inherit">
              <PhoneIcon />
            </IconButton>{" "}
            <span>{phoneNumber}</span>
          </a>
        </strong>
      </MaterialToolbar>
    </AppBar>
  );
};

export default Footer;
