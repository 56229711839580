import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  menu: {
    background: `${process.env.REACT_APP_HEADER_COLOR}`
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  },
  logo: {
    marginTop: 15,
    display: "inline-block",
    height: 58
  }
}));

const NavToolbar = () => {
  const classes = useStyles();

  return (
    <AppBar position="fixed" className={classes.menu}>
      <Toolbar>
        <Typography variant="h6" className={classes.title}>
          <img
            alt="Farmer Trials/IN10T Logo"
            className={classes.logo}
            src={process.env.REACT_APP_LOGO_URL}
          />
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default NavToolbar;
