import React, { useCallback, useEffect, useState } from "react";
import AppContext from "./AppContext";
import NavToolbar from "./Nav/Toolbar";
import Footer from "./Nav/Footer";
import DataUpload from "./DataUpload";
import { makeStyles } from "@material-ui/core/styles";
import OverlayLoader from "./components/OverlayLoader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import queryString from "query-string";
import axios from "axios";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 100,
    marginBottom: 100
  }
}));

const App = () => {
  const [growerEmail, setGrowerEmail] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [overlayActive, setOverlayActive] = useState(false);
  const [upload, setUpload] = useState(null);
  const [trials, setTrials] = useState(null);
  const [selectedTrial, setSelectedTrial] = useState(null);
  const classes = useStyles();

  const nextStep = () => {
    setActiveStep(prevStep => prevStep + 1);
  };

  const backToEmailStep = () => {
    setActiveStep(0);
    setGrowerEmail("");
  };

  const backToUploadStep = () => {
    setActiveStep(1);
  };

  const fetchTrials = useCallback(async () => {
    try {
      if (!growerEmail) return;

      const query = queryString.stringify({ email: growerEmail });
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}grower/trials?${query}`
      );

      if (!data.trials || !data.trials.length) {
        toast.error(
          "Error: It looks like you are not currently enrolled in any trials"
        );
        backToEmailStep();
      } else {
        setTrials(data.trials);
        setSelectedTrial(data.trials[0]);
      }
    } catch (error) {
      console.error(error);
      toast.error("An error has occurred");
    }
  }, [growerEmail]);

  useEffect(() => {
    fetchTrials();
  }, [fetchTrials]);

  return (
    <AppContext.Provider
      value={{
        growerEmail,
        setGrowerEmail,
        setOverlayActive,
        nextStep,
        activeStep,
        backToEmailStep,
        backToUploadStep,
        upload,
        setUpload,
        trials,
        selectedTrial
      }}
    >
      <OverlayLoader active={overlayActive}>
        <div>
          <NavToolbar />
          <div className={classes.root}>
            <DataUpload />
          </div>
          <Footer />
          <ToastContainer />
        </div>
      </OverlayLoader>
    </AppContext.Provider>
  );
};

export default App;
