import React, {
  useState,
  useRef,
  useCallback,
  useContext,
  useEffect,
} from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { toast } from "react-toastify";
import axios from "axios";
import AppContext from "../AppContext";
import queryString from "query-string";
import * as Yup from "yup";
import ReactSelectControlled from "../components/ReactSelectControlled";
import ReactSelectCreatableControlled from "../components/ReactSelectCreatableControlled";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    padding: 20,
  },
  uploadType: {
    width: "100%",
  },
  addFab: {
    marginTop: 20,
    marginLeft: 20,
    "&:hover": {
      cursor: "pointer",
    },
    clear: {
      marginRight: 20,
    },
  },
  formControls: {
    marginTop: 25,
  },
  formControlLabel: {
    marginBottom: 10,
  },
  formControl: {
    marginBottom: 10,
  },
}));

let dropzoneKey = 0;
const MAX_FILE_SIZE = 5000000; // 5GB

const UploadFormSchema = Yup.object().shape({
  selectedUploadType: Yup.object().required("Required"),
});

const UploadForm = () => {
  const {
    growerEmail,
    backToEmailStep,
    nextStep,
    setUpload,
    upload,
  } = useContext(AppContext);
  const [uploadTypes, setUploadTypes] = useState(null);
  const [trials, setTrials] = useState(null);
  // const [farms, setFarms] = useState(null);
  const [fields, setFields] = useState(null);
  const [files, setFiles] = useState(
    upload && upload.files ? upload.files : null
  );
  const chooseFileButton = useRef(null);
  const classes = useStyles();

  const checkMimeType = (event, uploadType) => {
    // require upload type be selected
    if (!uploadType) {
      event.target.value = null;
      toast.warn("Please select an Upload Type first.");
      return false;
    }

    // No restrictions for boundaries
    if (uploadType.value === "1") return true;

    let files = event.target.files;
    let err = "";
    const types = [
      "application/zip",
      "application/octet-stream",
      "application/x-zip",
      "application/x-zip-compressed",
    ];
    for (let x = 0; x < files.length; x++) {
      if (types.every((type) => files[x].type !== type)) {
        err += files[x].type + " is not a supported format\n";
      }
    }

    if (err !== "") {
      event.target.value = null;
      console.error(err);
      toast.warn("Only zipped shapefiles are accepted for this data type.");
      return false;
    }
    return true;
  };

  const checkFileSize = (event) => {
    const files = event.target.files;
    let err = "";
    for (let x = 0; x < files.length; x++) {
      if (Math.round(files[x].size / 1024) > MAX_FILE_SIZE) {
        err += files[x].type + " is too large, please pick a smaller file\n";
      }
    }

    if (err !== "") {
      event.target.value = null;
      toast.warn("File size is too large.  Please select a smaller file.");
      console.log(err);
      return false;
    }

    return true;
  };

  const handleFileChange = (event, uploadType) => {
    const files = event.target.files;
    if (
      //checkFileCount(event) &&
      checkMimeType(event, uploadType) &&
      checkFileSize(event)
    ) {
      setFiles(files);
    }
  };

  const getFileNamesLabel = () => {
    if (!files || !files.length) return "No file chosen";
    return files[0].name;
  };

  const fetchUploadTypes = useCallback(async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}portal/upload-types`
      );
      setUploadTypes(
        data.uploadTypes
          .filter((item) => !item.is_admin_only)
          .map((item) => ({
            value: String(item.id),
            label: item.name.charAt(0).toUpperCase() + item.name.slice(1),
          }))
      );
    } catch (error) {
      console.error(error);
      toast.error("An error has occurred");
    }
  }, []);

  // const fetchFarms = useCallback(async () => {
  //   try {
  //     const query = queryString.stringify({ email: growerEmail });
  //     const { data } = await axios.get(
  //       `${process.env.REACT_APP_API_URL}grower/farms?${query}`
  //     );

  //     setFarms(
  //       data.map(item => ({
  //         value: item.name,
  //         label: item.name
  //       }))
  //     );
  //   } catch (error) {
  //     console.error(error);
  //     toast.error("An error has occurred");
  //   }
  // }, [growerEmail]);

  const fetchFields = useCallback(async () => {
    try {
      const query = queryString.stringify({ email: growerEmail });
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}grower/fields?${query}`
      );

      setFields(
        data.fields.map((item) => ({
          value: item.name,
          label: item.name,
        }))
      );
    } catch (error) {
      console.error(error);
      setTimeout(() => {
        toast.dismiss();
        toast.error("An error has occurred");
      }, 1000);
    }
  }, [growerEmail]);

  useEffect(() => {
    fetchUploadTypes();
  }, [fetchUploadTypes]);

  // useEffect(() => {
  //   fetchFarms();
  // }, [fetchFarms]);

  useEffect(() => {
    fetchFields();
  }, [fetchFields]);

  return (
    <Formik
      initialValues={{
        selectedUploadType:
          upload && upload.selectedUploadType ? upload.selectedUploadType : "",
        selectedFarm: upload && upload.selectedFarm ? upload.selectedFarm : "",
        selectedField:
          upload && upload.selectedField ? upload.selectedField : "",
        notes: upload && upload.notes ? upload.notes : "",
      }}
      validationSchema={UploadFormSchema}
      onSubmit={async (values, { setSubmitting }) => {
        setUpload({
          ...values,
          files,
        });
        setSubmitting(false);
        nextStep();
        toast.success("Please review your entries");
      }}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        handleChange,
        handleSubmit,
        resetForm,
        setFieldValue,
        setFieldTouched,
      }) => (
        <form onSubmit={handleSubmit}>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item md={8} sm={12} xs={12}>
              <Paper className={classes.root}>
                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                >
                  <Grid item md={5} sm={12} xs={12}>
                    <ReactSelectControlled
                      label="Upload Type"
                      field="selectedUploadType"
                      value={values.selectedUploadType}
                      className={classes.formControl}
                      options={uploadTypes}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      error={errors.selectedUploadType}
                      touched={touched.selectedUploadType}
                    />
                    {/* <ReactSelectCreatableControlled
                      label="Farm"
                      field="selectedFarm"
                      value={values.selectedFarm}
                      className={classes.formControl}
                      options={farms}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      error={errors.selectedFarm}
                      touched={touched.selectedFarm}
                      placeholder="Enter/Select a Farm..."
                    /> */}
                    <ReactSelectCreatableControlled
                      label="Field"
                      field="selectedField"
                      value={values.selectedField}
                      className={classes.formControl}
                      options={fields}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      error={errors.selectedField}
                      touched={touched.selectedField}
                      placeholder="Enter/Select a Field..."
                    />
                    <div>
                      <TextField
                        variant="outlined"
                        label="Notes (Optional)"
                        name="notes"
                        multiline
                        rows="7"
                        rowsMax="10"
                        value={values.notes}
                        fullWidth
                        onChange={handleChange("notes")}
                        margin="normal"
                      />
                    </div>
                  </Grid>
                  <Grid item md={1} sm={12} xs={12} />
                  <Grid item md={6} sm={12} xs={12}>
                    <div>
                      <label>Upload Your File </label>
                      <div className="form-group files">
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ marginRight: 10 }}
                          onClick={() => chooseFileButton.current.click()}
                        >
                          <strong>Choose File</strong>
                        </Button>
                        <label>
                          <strong>Or Drag the file here</strong>
                        </label>
                        <input
                          key={dropzoneKey}
                          ref={chooseFileButton}
                          type="file"
                          name="files"
                          onChange={(event) =>
                            handleFileChange(event, values.selectedUploadType)
                          }
                        />
                        <label>{getFileNamesLabel()}</label>
                      </div>
                    </div>
                    <div className={classes.formControls}>
                      <Grid container justify="flex-end">
                        <Button
                          variant="contained"
                          style={{ marginRight: 10 }}
                          color="default"
                          onClick={backToEmailStep}
                        >
                          <strong>Back</strong>
                        </Button>
                        <Button
                          variant="contained"
                          style={{ marginRight: 10 }}
                          color="secondary"
                          onClick={() => {
                            setUpload(null);
                            setTimeout(() => {
                              setFiles(null);
                              dropzoneKey++;
                              resetForm();
                            }, 1);
                          }}
                        >
                          <strong>Clear</strong>
                        </Button>
                        <Button
                          variant="contained"
                          onClick={handleSubmit}
                          color="primary"
                          disabled={isSubmitting || !files || !files.length}
                        >
                          <strong>Review</strong>
                        </Button>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default UploadForm;
