import React from "react";
import Select from "react-select";

class ReactSelectControlled extends React.Component {
  handleChange = value => {
    console.log("handleChange", value);
    this.props.onChange(this.props.field, value);
  };

  handleBlur = () => {
    console.log("handleBlur", this.props.field);
    this.props.onBlur(this.props.field, true);
  };

  render() {
    return (
      <div style={{ margin: "1rem 0" }}>
        <div style={{ marginBottom: ".5rem" }}>
          <label htmlFor="color">
            <strong>{this.props.label}</strong>
          </label>
        </div>
        <Select
          id="color"
          options={this.props.options}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          value={this.props.value}
        />
        {!!this.props.error && this.props.touched && (
          <div style={{ color: "red", marginTop: ".5rem" }}>
            {this.props.error}
          </div>
        )}
      </div>
    );
  }
}

export default ReactSelectControlled;
