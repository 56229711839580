import React, { useContext } from "react";
import AppContext from "../AppContext";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Formik } from "formik";
import Button from "@material-ui/core/Button";
import axios from "axios";
import queryString from "query-string";
import { toast } from "react-toastify";
import PersonIcon from "@material-ui/icons/Person";

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 400,
    display: "flex"
  },
  center: {
    margin: "auto",
    display: "flex"
  },
  item: {
    marginBottom: 20
  },
  container: {
    display: "flex"
  },
  button: {
    marginRight: theme.spacing(1)
  }
}));

const EmailEntry = () => {
  const { setGrowerEmail, setOverlayActive, nextStep } = useContext(AppContext);
  const classes = useStyles();

  return (
    <Formik
      initialValues={{ email: "" }}
      validate={values => {
        const errors = {};
        if (!values.email) {
          errors.email = "Required";
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = "Invalid email address";
        }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        setOverlayActive(true);

        const query = queryString.stringify(values);
        try {
          const { data } = await axios.get(
            `${process.env.REACT_APP_API_URL}email-verify-user?${query}`
          );
          if (data && data.validUser) {
            setGrowerEmail(values.email);
            nextStep();
          } else {
            toast.warn("Validation failed. Please try again");
          }
        } catch (error) {
          console.error(`Error validating user: ${values.email}`, error);
          toast.error("An error has occurred");
        }

        setSubmitting(false);
        setOverlayActive(false);
      }}
    >
      {({ values, errors, touched, handleChange, handleSubmit, resetForm }) => (
        <form onSubmit={handleSubmit}>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item md={6} sm={8} xs={12}>
              <Paper className={classes.root}>
                <div className={classes.center}>
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item xs={3} />
                    <Grid item xs={6} className={classes.container}>
                      <div className={classes.center}>
                        <PersonIcon color="default" fontSize="large" />
                      </div>
                    </Grid>
                    <Grid item xs={3} />
                    <Grid item xs={3} />
                    <Grid item xs={6} className={classes.container}>
                      <Typography
                        variant="h6"
                        component="h6"
                        className={classes.item}
                      >
                        <strong>Please enter your email address</strong>
                      </Typography>
                    </Grid>
                    <Grid item xs={3} />
                    <Grid item xs={3} />
                    <Grid item xs={6} className={classes.container}>
                      <TextField
                        className={classes.item}
                        fullWidth
                        id="grower-email"
                        name="email"
                        variant="outlined"
                        value={values.email}
                        onChange={handleChange}
                        error={errors.email && touched.email && errors.email}
                        helperText={
                          errors.email && touched.email && errors.email
                        }
                      />
                    </Grid>
                    <Grid item xs={3} />
                    <Grid item xs={3} />
                    <Grid item xs={6} className={classes.container}>
                      <div className={classes.center}>
                        <Button
                          className={classes.button}
                          color="default"
                          variant="contained"
                          disabled={!values.email}
                          onClick={resetForm}
                        >
                          <strong>Clear</strong>
                        </Button>
                        <Button
                          className={classes.button}
                          color="primary"
                          variant="contained"
                          disabled={!values.email}
                          onClick={handleSubmit}
                        >
                          <strong>Verify</strong>
                        </Button>
                      </div>
                    </Grid>
                    <Grid item xs={3} />
                  </Grid>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default EmailEntry;
