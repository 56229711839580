import React, { useContext, useState } from "react";
import AppContext from "../AppContext";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import LinearProgress from "@material-ui/core/LinearProgress";
import axios from "axios";
import { toast } from "react-toastify";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    padding: 30
  },
  container: {
    display: "flex"
  },
  center: {
    margin: "auto",
    display: "flex"
  },
  button: {
    marginRight: theme.spacing(1)
  },
  reviewFields: {
    marginBottom: 25
  },
  lineItem: {
    marginTop: 20,
    marginBottom: 20
  }
}));

const createDataUpload = async ({
  trialId,
  uploadType,
  files,
  notes,
  field,
  farm,
  email,
  setLoadedProgress
}) => {
  try {
    const formData = new FormData();
    formData.append("email", email);
    formData.append("trialId", trialId);
    formData.append("uploadType", uploadType);
    formData.append("notes", notes);
    if (field) formData.append("field", field);
    if (farm) formData.append("farm", farm);
    formData.append("file", files[0]);

    const result = await axios.post(
      `${process.env.REACT_APP_API_URL}grower/data-uploads`,
      formData,
      {
        onUploadProgress: ProgressEvent => {
          setLoadedProgress((ProgressEvent.loaded / ProgressEvent.total) * 100);
        }
      }
    );

    const { data } = result;
    return data;
  } catch (err) {
    console.error("error creating user upload", err);
    throw new Error(err);
  }
};

const UploadReview = () => {
  const {
    upload,
    setUpload,
    backToUploadStep,
    setOverlayActive,
    growerEmail,
    nextStep,
    selectedTrial
  } = useContext(AppContext);
  const classes = useStyles();
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [loadedProgress, setLoadedProgress] = useState(0);

  const handleUpload = async () => {
    setOverlayActive(true);
    setShowProgressBar(true);

    try {
      await createDataUpload({
        trialId: selectedTrial.id,
        uploadType: upload.selectedUploadType.value,
        files: upload.files,
        notes: upload.notes,
        field: upload.selectedField ? upload.selectedField.value : null,
        farm: upload.selectedFarm ? upload.selectedFarm.value : null,
        email: growerEmail,
        setLoadedProgress
      });

      setShowProgressBar(false);
      setOverlayActive(false);
      nextStep();
      toast.success("File successfully uploaded");
      setUpload(null);
    } catch (error) {
      setShowProgressBar(false);
      setOverlayActive(false);
      toast.error("An Error has occurred.  Please try again or contact us.");
    }
  };

  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <Grid item md={6} sm={8} xs={12}>
        <Paper className={classes.root}>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={12} className={classes.container}>
              <div className={classes.center}>
                <CloudUploadIcon color="default" fontSize="large" />
              </div>
            </Grid>
            <Grid item xs={12} className={classes.container}>
              <div className={classes.center}>
                <Typography
                  variant="h6"
                  component="h6"
                  className={classes.item}
                >
                  <strong>Review</strong>
                </Typography>
              </div>
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={8} className={classes.container}>
              <div className={classes.reviewFields}>
                <div className={classes.lineItem}>
                  <span>
                    <strong>File</strong>
                  </span>
                  <br />
                  {upload.files && upload.files.length && upload.files[0].name}
                </div>
                <div className={classes.lineItem}>
                  <span>
                    <strong>Upload Type</strong>
                  </span>
                  <br />
                  {upload.selectedUploadType
                    ? upload.selectedUploadType.label
                    : ""}
                </div>
                {upload.selectedFarm && (
                  <div className={classes.lineItem}>
                    <span>
                      <strong>Farm</strong>
                    </span>
                    <br />
                    {upload.selectedFarm ? upload.selectedFarm.value : ""}
                  </div>
                )}
                {upload.selectedField && (
                  <div className={classes.lineItem}>
                    <span>
                      <strong>Field</strong>
                    </span>
                    <br />
                    {upload.selectedField ? upload.selectedField.value : ""}
                  </div>
                )}
                {upload.notes && (
                  <div className={classes.lineItem}>
                    <span>
                      <strong>Notes</strong>
                    </span>
                    <br />
                    {upload.notes && upload.notes}
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={12} className={classes.container}>
              <div className={classes.center}>
                <Button
                  className={classes.button}
                  color="default"
                  variant="contained"
                  onClick={backToUploadStep}
                >
                  <strong>Back</strong>
                </Button>
                <Button
                  className={classes.button}
                  color="primary"
                  variant="contained"
                  onClick={handleUpload}
                >
                  <strong>Upload</strong>
                </Button>
              </div>
            </Grid>
          </Grid>
        </Paper>
        {showProgressBar && (
          <Dialog
            open={showProgressBar}
            onClose={() => {}}
            fullWidth={true}
            maxWidth={"md"}
          >
            <DialogTitle>Upload in Progress</DialogTitle>
            <DialogContent>
              <DialogContentText>
                <div>
                  <LinearProgress
                    variant="determinate"
                    value={loadedProgress}
                  />
                </div>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        )}
      </Grid>
    </Grid>
  );
};

export default UploadReview;
