import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Grid from "@material-ui/core/Grid";
import EmailEntry from "./EmailEntry";
import AppContext from "../AppContext";
import UploadForm from "./UploadForm";
import UploadReview from "./UploadReview";
import Confirmation from "./Confirmation";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  button: {
    marginRight: theme.spacing(1)
  },
  gridRow: {
    marginBottom: theme.spacing(3)
  },
  center: {
    margin: "auto"
  },
  container: {
    display: "flex"
  }
}));

function getSteps() {
  return ["Verify Email", "Upload Data", "Review", "Confirm"];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return <EmailEntry />;
    case 1:
      return <UploadForm />;
    case 2:
      return <UploadReview />;
    case 3:
      return <Confirmation />;
    default:
      return null;
  }
}

const DataUpload = () => {
  const { activeStep } = useContext(AppContext);
  const classes = useStyles();
  const steps = getSteps();

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className={classes.gridRow}
      >
        <Grid item md={6} sm={8} xs={12}>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps = { completed: false };
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className={classes.gridRow}
      >
        <Grid item md={10} sm={12} xs={12}>
          {getStepContent(activeStep)}
        </Grid>
      </Grid>
    </div>
  );
};

export default DataUpload;
