import React, { useContext } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import CloudDoneIcon from "@material-ui/icons/CloudDone";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import AppContext from "../AppContext";

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 400,
    display: "flex"
  },
  center: {
    margin: "auto",
    display: "flex"
  },
  item: {
    marginBottom: 20
  },
  container: {
    display: "flex"
  },
  button: {
    marginRight: theme.spacing(1)
  }
}));

const Confirmation = () => {
  const { backToUploadStep } = useContext(AppContext);
  const classes = useStyles();
  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <Grid item md={6} sm={8} xs={12}>
        <Paper className={classes.root}>
          <div className={classes.center}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item xs={3} />
              <Grid item xs={6} className={classes.container}>
                <div className={classes.center}>
                  <CloudDoneIcon color="default" fontSize="large" />
                </div>
              </Grid>
              <Grid item xs={3} />
              <Grid item xs={3} />
              <Grid item xs={6} className={classes.container}>
                <Typography
                  variant="h6"
                  component="h6"
                  className={classes.item}
                >
                  <strong>We have received your file!</strong>
                </Typography>
              </Grid>
              <Grid item xs={3} />
              <Grid item xs={3} />
              <Grid item xs={6} className={classes.container}>
                <div className={classes.center}>
                  <Button
                    className={classes.button}
                    color="primary"
                    variant="contained"
                    onClick={backToUploadStep}
                  >
                    <strong>Upload Another File</strong>
                  </Button>
                </div>
              </Grid>
              <Grid item xs={3} />
            </Grid>
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Confirmation;
