import React from "react";
import LoadingOverlay from "react-loading-overlay";

const OverlayLoader = ({ active, children }) => (
  <LoadingOverlay
    active={active}
    spinner
    styles={{
      overlay: base => ({
        ...base,
        height: "100%",
        width: "100%",
        position: "fixed",
        top: 0,
        left: 0
      })
    }}
  >
    {children}
  </LoadingOverlay>
);

export default OverlayLoader;
